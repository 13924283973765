'use strict'

###*
 # @ngdoc object
 # @name home
 # @description

###
angular
  .module 'home', [
    'ui.router'
  ]
  .run [
    '_'
    '$rootScope'
    '$translate'
    'mundoConfiguration'
    'MundoNavigation'
    (_, $rootScope, $translate, mundoConfiguration, MundoNavigation) ->
      MundoNavigation.addSection
        state: 'home'
        label: 'app.home'
        icon: 'home'

      if not $rootScope.branding?
        $rootScope.branding = {}

      $rootScope.branding.windowTitle = null
      $rootScope.branding.homeState = mundoConfiguration.application.homeState

      $rootScope
        .$on '$stateChangeStart', (event, toState, toParams, fromState, fromParams) ->
          # Attempt to set window title dynamically
          windowTitle = $rootScope.branding.theme.applicationName

          stateName = if toState.data? and toState.data.title? then toState.data.title else null
          stateName ?= _.capitalize toState.name.replace(/[_\.]/g, ' ')

          if stateName?
            windowTitle = "#{stateName} &mdash; #{windowTitle}"

          $rootScope.branding.windowTitle = windowTitle
          $rootScope.branding.pageTitle = stateName
  ]
